<template>
  <div>
    <b-card-group deck>
      <b-card header-tag="header" footer-tag="footer">
        <div class="m-portlet__body row">
          <div class="col-md-4" v-if="response">
            <div class="table-responsive">
              <table class="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th width="35%" style="background: #c4c4c4">
                      Thông tin tài khoản
                    </th>
                    <th style="background: #c4c4c4">Trạng thái</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Họ và tên</td>
                    <td>{{ response.name }}</td>
                  </tr>
                  <tr>
                    <td>Ngày Sinh</td>
                    <td>
                      {{
                        moment(String(response.birthday)).format("DD-MM-YYYY")
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Quốc Tịch</td>
                    <td>{{ response.country }}</td>
                  </tr>
                  <tr>
                    <td>Số CMND/CCCD</td>
                    <td>{{ response.id_card_no }}</td>
                  </tr>
                  <tr>
                    <td>Số hộ chiếu</td>
                    <td>{{ response.passport_number }}</td>
                  </tr>
                  <tr>
                    <td>Ngày cấp</td>
                    <td>
                      {{
                        moment(String(response.card_issue_at)).format(
                          "DD-MM-YYYY"
                        )
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Nơi cấp</td>
                    <td>
                      {{ response.card_issue_place }}
                    </td>
                  </tr>
                  <tr>
                    <td>Số điện thoại</td>
                    <td>
                      <a :href="'tel' + response.phone">{{ response.phone }}</a>
                    </td>
                  </tr>
                  <tr>
                    <td>Giới Tính</td>
                    <td>
                      {{
                        response.gender
                          ? genders.find(
                              (item) => item.key === parseInt(response.gender)
                            ).value
                          : ""
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Tỉnh/ Thành phố</td>
                    <td>{{ response.province }}</td>
                  </tr>
                  <tr>
                    <td>Quận/ Huyện</td>
                    <td>{{ response.district }}</td>
                  </tr>
                  <tr>
                    <td>Phường/ Xã</td>
                    <td>{{ response.ward }}</td>
                  </tr>
                  <tr>
                    <td>Địa chỉ cụ thể</td>
                    <td>{{ response.address }}</td>
                  </tr>

                  <tr>
                    <td>Email</td>
                    <td>
                      <a :href="'mailto:' + response.email">{{
                        response.email
                      }}</a>
                    </td>
                  </tr>
                  <tr>
                    <td>Ngày tạo</td>
                    <td>
                      {{
                        moment(String(response.created_at)).format(
                          "HH:mm:ss DD-MM-YYYY"
                        )
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Ngày cập nhật</td>
                    <td>
                      {{
                        moment(String(response.updated_at)).format(
                          "HH:mm:ss DD-MM-YYYY"
                        )
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Trạng thái</td>
                    <td>
                      <span class="text-secondary">{{
                        convertStatusLabel(response.status).label
                      }}</span>
                    </td>
                  </tr>
                  <tr v-if="response.status === 2">
                    <td>Lý do</td>
                    <td>
                      <span class="text-secondary">{{ response.reason }}</span>
                    </td>
                  </tr>
                  <tr v-if="response.status !== 0">
                    <td>Người Duyệt</td>
                    <td>
                      <span class="text-secondary">{{
                        getAdminName(response.updated_by)
                      }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="col-md-8" v-if="response">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th width="100%" colspan="2" style="background: #c4c4c4">
                    Thông tin ảnh
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td style="width: 50%" v-if="response.img_front_card">
                    <div class="img-border">
                      <p>Ảnh (1) CMND/Thẻ CCCD/Hộ chiếu</p>
                      <img
                        @click="openImage(response.img_front_card)"
                        :src="response.img_front_card"
                        height="200px"
                        :alt="response.name"
                      />
                    </div>
                  </td>
                  <td v-if="response.img_back_card">
                    <div class="img-border">
                      <p>Ảnh (2) CMND/Thẻ CCCD/Hộ chiếu</p>
                      <img
                        @click="openImage(response.img_back_card)"
                        :src="response.img_back_card"
                        height="200px"
                        :alt="response.name"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td v-if="response.selfie_image">
                    <div class="img-border">
                      <p>Ảnh (3) chụp chân dung</p>
                      <img
                        @click="openImage(response.selfie_image)"
                        :src="response.selfie_image"
                        height="200px"
                        :alt="response.name"
                      />
                    </div>
                  </td>
                  <td v-if="response.nfc_image">
                    <div class="img-border">
                      <p>Ảnh (4) Ảnh trong NFC</p>
                      <img
                        @click="openImage(response.nfc_image)"
                        :src="response.nfc_image"
                        height="200px"
                        :alt="response.name"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div
                      class=""
                      v-if="response.status === 0 || response.status === 9"
                    >
                      <button
                        @click="approveRequest()"
                        class="m-portlet__nav-link--icon btn btn-success"
                        title="Đồng ý"
                      >
                        <i class="fa fa-check"></i> Đồng ý
                      </button>

                      <button
                        type="button"
                        class="btn btn-danger m-portlet__nav-link--icon"
                        @click="cancelRequest()"
                      >
                        <i class="fa fa-remove"></i> Từ chối
                      </button>
                    </div>
                  </td>
                  <td class="text-right">
                    <button
                      class="btn btn-danger"
                      title="Khóa"
                      @click="lock()"
                      data-toggle="modal"
                      data-target="#lockModal"
                    >
                      <i class="fa fa-remove"></i> Khóa
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </b-card>
    </b-card-group>
    <b-modal
      id="lock-account"
      hide-footer
      ref="myModal"
      :title="titleModalLockAccount"
    >
      <b-form class="col-12">
        <b-form-group label="Lý do">
          <b-form-textarea v-model="reasonLockAccount"></b-form-textarea>
        </b-form-group>
        <b-row style="float: right">
          <b-button
            class="mr-2"
            type="submit"
            variant="primary"
            v-on:click="
              $bvModal.hide('lock-account');
              reasonLockAccount = '';
            "
            >Hủy
          </b-button>
          <b-button
            type="button"
            variant="primary"
            v-on:click="onChangeStatusUser()"
            >Lưu
          </b-button>
        </b-row>
      </b-form>
    </b-modal>

    <b-modal
      id="reject-verify"
      hide-footer
      ref="myModalReject"
      :title="titleModalReject"
    >
      <b-form class="col-12">
        <div class="form-group">
          <label for="reason">1.Thông tin ảnh</label>
          <div class="form-inline">
            <input
              id="rs1_1"
              type="checkbox"
              value="Ảnh mặt trước và mặt sau không cùng loại"
              @click="choseReason('rs1_1')"
            />
            <label for="rs1_1"> Ảnh mặt trước và mặt sau không cùng loại</label>
          </div>
          <div class="form-inline">
            <input
              id="rs1_2"
              type="checkbox"
              value="Ảnh bị thiếu một phần"
              @click="choseReason('rs1_2')"
            />
            <label for="rs1_2"> Ảnh bị thiếu một phần</label>
          </div>
          <div class="form-inline">
            <input
              id="rs1_3"
              type="checkbox"
              value="Ảnh bị mờ"
              @click="choseReason('rs1_3')"
            />
            <label for="rs1_3"> Ảnh bị mờ</label>
          </div>
          <div class="form-inline">
            <input
              id="rs1_4"
              type="checkbox"
              value="Không phải ảnh thẻ mặt trước"
              @click="choseReason('rs1_4')"
            />
            <label for="rs1_4"> Không phải ảnh thẻ mặt trước</label>
          </div>
          <div class="form-inline">
            <input
              id="rs1_5"
              type="checkbox"
              value="Không phải ảnh thẻ mặt sau"
              @click="choseReason('rs1_5')"
            />
            <label for="rs1_5"> Không phải ảnh thẻ mặt sau</label>
          </div>
        </div>
        <div class="form-group">
          <label for="reason">2.Thông tin kê khai</label>
          <div class="form-inline">
            <input
              id="rs2_1"
              type="checkbox"
              value="Giấy tờ tuỳ thân đã hết hạn "
              @click="choseReason('rs2_1')"
            />
            <label for="rs2_1"> Giấy tờ tuỳ thân đã hết hạn </label>
          </div>
        </div>
        <div class="form-group">
          <label for="reason">3. So sánh ảnh &amp; thông tin kê khai </label>
          <div class="form-inline">
            <input
              id="rs3_1"
              type="checkbox"
              value="Thông tin kê khai không trùng khớp với ảnh "
              @click="choseReason('rs3_1')"
            />
            <label for="rs3_1">
              Thông tin kê khai không trùng khớp với ảnh
            </label>
          </div>
          <div class="form-inline">
            <input
              id="rs3_2"
              type="checkbox"
              value="Ảnh chụp chân dung không giống với ảnh trên giấy tờ tuỳ thân "
              @click="choseReason('rs3_2')"
            />
            <label for="rs3_2">
              Ảnh chụp chân dung không giống với ảnh trên giấy tờ tuỳ thân
            </label>
          </div>
        </div>
        <b-form-group label="Lý do khác">
          <b-form-textarea v-model="reasonReject"></b-form-textarea>
        </b-form-group>
        <b-row style="float: right">
          <b-button
            class="mr-2"
            type="submit"
            variant="primary"
            v-on:click="
              $bvModal.hide('reject-verify');
              reasonReject = '';
            "
            >Hủy
          </b-button>
          <b-button
            type="button"
            variant="primary"
            v-on:click="handleCancelRequest()"
            >Lưu
          </b-button>
        </b-row>
      </b-form>
    </b-modal>
  </div>
</template>
<script>
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Notifications from "vue-notification";
import Common from "@/core/mixins/common";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Vue from "vue";
import router from "@/router";
import moment from "moment-timezone";

const CmsRepository = RepositoryFactory.get("cms");
Vue.use(Notifications);
export default {
  mixins: [Common],
  components: {},
  data() {
    return {
      id: this.$route.params.id,
      account: null,
      admin: null,
      response: null,
      genders: [
        { key: 0, value: "N/A" },
        { key: 1, value: "Nam" },
        { key: 2, value: "Nữ" },
      ],
      titleModalLockAccount: "",
      reasonLockAccount: "",
      titleModalReject: "",
      reasonReject: "",
      arrIdReason: [],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: " Chi tiết tài khoản ", route: "/#/tools/user/request-verify" },
    ]);
  },
  methods: {
    choseReason(id) {
      if (!this.arrIdReason.includes(id)) {
        this.arrIdReason.push(id);
      } else {
        this.arrIdReason = this.arrIdReason.filter((item) => item !== id);
      }
    },
    moment,
    openImage(url) {
      if (url) {
        window.open(url, "_blank").focus();
      }
    },
    cancelRequest() {
      this.titleModalReject = "Từ chối chối xác thực tài khoản";
      this.reasonReject = "";
      this.$bvModal.show("reject-verify");
    },
    handleCancelRequest() {
      let thisReason = "";
      if (this.reasonReject.length > 100) {
        alert("Lý do khác không được vượt quá 100 ký tự!");
        return false;
      }
      if (this.reasonReject.length === 0 && this.arrIdReason.length === 0) {
        alert("Chọn hoặc nhập lý do từ chối xác thực!");
        return false;
      }
      this.arrIdReason.forEach(function (value) {
        let element = document.getElementById(value);
        if (element) {
          thisReason += element.value + "\n";
        }
      });
      thisReason += this.reasonReject + "\n";

      this.$bus.$emit("show-loading", true);
      CmsRepository.rejectRequestVerify(this.id, thisReason)
        .then((response) => {
          this.$bvModal.hide("reject-verify");
          if (response.data.error_code !== 0) {
            this.$bus.$emit("show-loading", false);
            this.notifyAlert("warn", response.data.message || "Có lỗi xẩy ra");
            return;
          }

          this.notifyAlert("success", "Từ chối thành công");

          this.getDetail();

          this.$bus.$emit("show-loading", false);
        })
        .catch(() => {
          this.$bus.$emit("show-loading", false);
          alert("Có lỗi xảy ra");
        });
    },
    approveRequest() {
      let conf = confirm("Bạn có chấp nhận yêu cầu này?");
      if (!conf) {
        return;
      }
      this.$bus.$emit("show-loading", true);
      CmsRepository.ApproveRequestVerify(this.id)
        .then((response) => {
          this.$bvModal.hide("lock-account");
          if (response.data.error_code !== 0) {
            this.$bus.$emit("show-loading", false);
            this.notifyAlert("warn", response.data.message || "Có lỗi xẩy ra");
            return;
          }

          this.notifyAlert(
            "success",
            "Thay đổi trạng thái tài khoản thành công"
          );

          this.getDetail();

          this.$bus.$emit("show-loading", false);
        })
        .catch(() => {
          this.$bus.$emit("show-loading", false);
          alert("Có lỗi xảy ra");
        });
    },
    lock() {
      this.titleModalLockAccount = "Khóa tài khoản";
      this.reasonLockAccount = "";
      this.$bvModal.show("lock-account");
    },
    onChangeStatusUser() {
      this.$bus.$emit("show-loading", true);
      CmsRepository.changeStatusUser(this.account.id, {
        type: "LOCK",
        description: this.reasonLockAccount,
      })
        .then((response) => {
          this.$bvModal.hide("lock-account");
          if (response.data.error_code) {
            this.$bus.$emit("show-loading", false);
            this.notifyAlert("warn", response.data.message);
            return;
          }

          if (response.data.success) {
            // this.notifyAlert(
            //   "success",
            //   "Thay đổi trạng thái tài khoản thành công"
            // );
            // this.getDetail();
            alert("Thay đổi trạng thái tài khoản thành công");
            router.push({
              name: "AccountView",
              params: { id: this.account.id },
            });
          } else {
            this.notifyAlert(
              "warn",
              "Có lỗi khi thay đổi trạng thái tài khoản"
            );
          }

          this.$bus.$emit("show-loading", false);
        })
        .catch(() => {
          this.$bus.$emit("show-loading", false);
          alert("Có lỗi xảy ra");
        });
    },
    getAdminName(id) {
      if (parseInt(id) === 0 || !id) {
        return "Hệ thống";
      }
      return this.admin.fullname;
    },
    convertStatusLabel(status) {
      let arrStatus = {
        0: { class: "warning", label: "Chờ phê duyệt" },
        1: { class: "success", label: "Đã duyệt" },
        2: { class: "danger", label: "Từ chối" },
        9: { class: "warning", label: "Hồ sơ phụ đang chờ" },
      };

      if (arrStatus[status] === undefined) {
        return { class: "warning", label: "Chờ phê duyệt" };
      }

      return arrStatus[status];
    },
    convert(params) {
      for (let key in params) {
        if (params[key] == "" || params[key] == null) {
          delete params[key];
        }
      }
      return params;
    },
    async getDetail() {
      this.item = null;
      this.$bus.$emit("show-loading", true);
      CmsRepository.detailRequestVerifyAccount(this.id)
        .then((response) => {
          if (!response.data.success) {
            this.notifyAlert("warn", response.data.message);
            return;
          }

          this.account = response.data.account;
          this.admin = response.data.admin;
          this.response = response.data.response;
          this.$bus.$emit("show-loading", false);
        })
        .catch(() => {
          this.$bus.$emit("show-loading", false);
          alert("Có lỗi xảy ra");
        });
    },
  },
  created() {
    this.getDetail();
  },
  watch: {},
};
</script>
